import React, { useState } from "react";
import "./TryInspire.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import nextArrow from "../../../assets/image/png/nextArrow.png";
import girlWearingOrangeHoodie from "../../../assets/image/png/girlWearingOrangeHoodie.png";
import TryInspireModal from "../../Modals/TryInspireModal";

const TryInspire = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
    <div className="py-5 px-2">
      <Container
        style={{ borderRadius: "20px" }}
        className="inspire-section-bg pb-3 pb-xl-0"
      >
        <Row className="px-md-5 position-relative text-center text-md-start align-items-center">
          <Col className="pe-md-5" lg={9}>
            <p className="mb-0 ff-gotham-bold fs-32">
              Interested in an{" "}
              <span className="elevate-text">
                Inspire Mental Fitness Training Plan
              </span>{" "}
              Set up a free 20 minute discovery call to learn more
            </p>

            <Button
              onClick={handleShowModal}
              style={{ width: "150px", height: "45px", borderRadius: "10px" }}
              className="btn-green-common d-flex text-white align-items-center mt-4 mx-auto mx-md-0 gap-2 justify-content-center"
            >
              Get Inspired
              <img
                style={{ width: "13px", height: "10px" }}
                src={nextArrow}
                alt="nextArrow"
              />
            </Button>
          </Col>
          <Col lg={3} className="d-none d-md-block">
            <div>
              <img
                className="girlWearingOrangeHoodie"
                src={girlWearingOrangeHoodie}
                alt="girlWearingOrangeHoodie"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <TryInspireModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default TryInspire;
