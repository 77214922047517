import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { toast } from "react-toastify";
import {
  userSignupSuccess,
  userSignupFailure,
  setRedirectPath as setUserRedirectPath,
  setRedirectPath,
} from "../slice/UserSlice";
import {
  userLoginSuccess,
  userLoginFailure,
  setRedirectPath as setUserLoginRedirectPath,
} from "../slice/UserLoginSlice";
import {
  otpVerificationSuccess,
  otpVerificationFailure,
  setRedirectPath as setOtpRedirectPath,
} from "../slice/OtpVerificationSlice";

const API_URL = process.env.REACT_APP_API_URL;

function* userSignupSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/usersignup`,
      action.payload
    );
    console.log("Full response in try block:", response);

    const { status, message, data } = response.data;
    console.log("Status:", status);
    console.log("Message:", message);

    if (status === "success") {
      yield put(userSignupSuccess({ role: action.payload.role }));
      console.log("Dispatching userSignupSuccess");

      const dataToSend = {
        ...action.payload,
      };

      toast.success("Signup successful!");
      yield call(delay, 500);

      yield put(setRedirectPath({ path: "/otp-verification", dataToSend }));
    } else {
      console.log("Error message from response:", message);
      toast.error(message);
    }
  } catch (error) {
    console.log("Error caught in saga:", error);
    yield put(userSignupFailure(error.message));
    toast.error("An error occurred during signup.");
  }
}

function* userLoginSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/usersignin`,
      action.payload
    );
    const { data } = response;
    console.log("data", data);
    yield put(userLoginSuccess(data));
    const token = data?.token;

    if (data.status === "success") {
      localStorage.setItem("token", token);
      localStorage.setItem("userData", JSON.stringify(data?.data));

      const redirectPath =
        data?.data?.role === "Athlete"
          ? "/fieldhouse-dashboard"
          : "/coaching-dashboard";

      toast.success("Sign in Successfull");

      yield call(delay, 1000);

      yield put(setUserLoginRedirectPath(redirectPath));
    } else {
      console.log("data.message", data.message);
      toast.error(`${data.message}`);
    }
  } catch (error) {
    console.log("error", error);
    yield put(userLoginFailure(error.message));
    toast.error("Something went wrong during login!");
  }
}

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function* watchUserSignup() {
  yield takeLatest("user/userSignup", userSignupSaga);
}

export function* watchUserLogin() {
  yield takeLatest("userLogin/userLogin", userLoginSaga);
}
