import React, { useEffect, useState } from "react";
import CoachSessions from "./CoachSessions.jsx";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  createPaymentIntent,
  getAllSessions,
} from "../../../services/CounselingTicketRaising";
import GenerateLink from "../../fildHouseDashboard/Session/GenerateLink/GenerateLink";

const stripePromise = loadStripe(
  "pk_test_51QHgttDLx6OW67tJ0isSynBmZYx2nTzz0CzBPPsLmQ1XtPrEo2y0UPrjS8gUUayNwN9swjPJKqFSd9TRJ6fDmQfN00vuQs0G4t"
);

const CoachSessionPage = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [userSessions, setUserSessions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSessions = async () => {
    const response = await getAllSessions();
    setUserSessions(response?.tickets);
  };

  useEffect(() => {
    const loadPaymentIntent = async () => {
      const { clientSecret } = await createPaymentIntent(50 * 100);
      setClientSecret(clientSecret);
    };

    loadPaymentIntent();
    getSessions();
  }, []);

  if (!clientSecret) {
    return (
      <div
        style={{
          height: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="ff-gotham-medium fs_18"
      >
        Loading...
      </div>
    );
  }

  const options = { clientSecret };

  return (
    <div className="pt-4">
      <Elements stripe={stripePromise} options={options}>
        <GenerateLink getSessions={getSessions} />
      </Elements>
      <CoachSessions userSessions={userSessions} />
    </div>
  );
};

export default CoachSessionPage;
