import React from "react";
import "../components/landingPage/AboutUs/AboutUs.css";
import nextArrow from "../assets/image/png/nextArrow.png";
import mainImg from "../assets/image/png/girlWithRacket.png";
import { Button, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import AppNav from "../components/landingPage/AppNavbar/AppNav";
import AppFooter from "../components/landingPage/AppFooter/AppFooter";

const AboutUsPage = () => {
  return (
    <div className="landing-page-body">
      <AppNav />
      <div id="aboutus" className="py-5">
        <Container>
          <Row className="align-items-center flex-column-reverse flex-lg-row">
            <div className="d-flex align-items-center gap-4 justify-content-center">
              <hr className="horizontal-line" />
              <h3 className="ff-gotham-normal clr-black fs_20">About Us</h3>
              <hr className="horizontal-line" />
            </div>
            <h2
              style={{ maxWidth: "800px" }}
              className="mb-0 ff-gotham-bold fs-56 text-center mx-auto mt-3"
            >
              Get to <span className="elevate-text">Know Us</span> Better
            </h2>
            <Col className="mt-3" lg={6}>
              <div>
                <p
                  style={{ opacity: "90%" }}
                  className="mb-0 mt-4 ff-gotham-normal fs_16 clr-black"
                >
                  Inspire Mental Fitness was created with the{" "}
                  <span className=" elevate-text">Athlete in Mind</span>. We
                  equip our athletes with the tools and support they need to
                  face challenges, build confidence,envision goals and reach
                  their true potential both on and off the field. We don't just
                  talk about mental resilience, we build it!
                </p>
                <p
                  style={{ opacity: "90%" }}
                  className="mb-0 mt-2 ff-gotham-normal fs_16 clr-black"
                >
                  Our virtual platform offers personalized mental training plans
                  , interactive counseling sessions, self discovery tools,
                  mindfulness training and a wealth of other resources designed
                  specifically for athletes of all ages and skill levels.
                </p>
                <p
                  style={{ opacity: "90%" }}
                  className="mb-0 mt-2 ff-gotham-normal fs_16 clr-black"
                >
                  Conquer the mental game with Inspire Mental Fitness. Meet with
                  success, enhance your love of your sport and unleash your true
                  potential- Become Inspired
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-center position-relative">
                <img className="w-75" src={mainImg} alt="boyWithFootball" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <AppFooter />
    </div>
  );
};

export default AboutUsPage;
