import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "react-bootstrap";
import { createPaymentIntentForUserSignup } from "../services/CounselingTicketRaising";
import { toast, ToastContainer } from "react-toastify";
import { saveUser } from "../services/otpService";
import logo from "../assets/image/png/logo.png";

const stripePromise = loadStripe(
  "pk_test_51QHgttDLx6OW67tJ0isSynBmZYx2nTzz0CzBPPsLmQ1XtPrEo2y0UPrjS8gUUayNwN9swjPJKqFSd9TRJ6fDmQfN00vuQs0G4t"
);

const PaymentForm = () => {
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const { userData, type } = location.state || {};
  const navigate = useNavigate();

  const submitDetails = async () => {
    const userDataWithPlan = {
      ...userData,
      subscriptionPlan: type,
    };
    const response = await saveUser(userDataWithPlan);
    const { status, token, data } = response;
    if (status === "success") {
      localStorage.setItem("token", token);
      localStorage.setItem("userData", JSON.stringify(data));
      navigate("/fieldhouse-dashboard");
    }
  };

  const handlePayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe has not loaded.");
      return;
    }

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {},
        redirect: "if_required",
      });
      if (error) {
        toast.error("Payment failed");
      } else {
        submitDetails();
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={handlePayment}>
        <PaymentElement />
        <Button
          type="submit"
          disabled={!stripe}
          className="mt-3 w-100"
          style={{ backgroundColor: "#009345", color: "#fff" }}
        >
          Pay Now
        </Button>
      </form>
    </>
  );
};

const PaymentPage = () => {
  const location = useLocation();
  const { amount, currentDate } = location.state || {};
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const fetchClientSecret = async () => {
      const amountInCents = Math.round(amount * 100);
      try {
        const { clientSecret } = await createPaymentIntentForUserSignup(
          amountInCents,
          currentDate
        );
        setClientSecret(clientSecret);
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
    };

    if (amount && currentDate) {
      fetchClientSecret();
    }
  }, [amount, currentDate]);

  if (!amount || !currentDate) {
    return <div>Error: Missing payment details.</div>;
  }

  if (!clientSecret) {
    return <div>Loading payment details...</div>;
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <div style={{ width: "400px" }}>
          <div className="text-center">
            <img
              className="position-relative logo-index my-4"
              style={{ width: "180px", height: "55px" }}
              src={logo}
              alt="logo"
            />
          </div>
          <h4 className="ff-gotham-medium mb-4 text-center">
            Complete Your Payment
          </h4>
          <PaymentForm />
        </div>
      </div>
    </Elements>
  );
};

export default PaymentPage;
